/* eslint-disable react/jsx-newline */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import imgNurse from '@assets/images/backgrounds/agence-interim-sante.png'
import { Box, Center, Flex, Heading, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { HorizontalCard } from '@components/ui/Card'
import { HorizontalCarousel } from '@components/ui/Carousel'
import IconItemList from '@components/ui/IconItemList'
import Link from '@components/ui/Link'
import MenuSticky from '@components/ui/MenuSticky'
import { functionalities, horizontalCarouselContent, mission } from '@data/ehpad/lp/planning-infirmier'
import { device } from '@helpers/mediaQueryBreakpoints'
import { CALENDLY_URL } from '@helpers/url'
import { graphql } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

const NursePlanningPage = () => {
    const [isMobile] = useMediaQuery(device.xs)
    const [isTablet] = useMediaQuery('(min-width: 1000px)')

    return (
        <>
            <Seo
                title="Planning Infirmier EHPAD"
                description="Découvrez Staffea pour gérer facilement votre planning d'infirmier en EHPAD ➜ ✓ Appli mobile ✓ Prise en main rapide ✓ Gestion et partage en temps réel"
            />

            <main>
                <MenuSticky>
                    <Section
                        bg="indigo.100"
                        id="nurse-planning-staffea-section"
                        name="staffea"
                        backgroundImage={isTablet ? imgNurse : undefined}
                        backgroundPosition="right top"
                        backgroundRepeat="no-repeat"
                        backgroundSize="contain"
                        aria-label="Femme infirmière consulte l'application web Staffea"
                    >
                        <Flex direction="column" align="start" textAlign="left" maxW="850" py={20}>
                            <Heading
                                as="h1"
                                fontWeight="bold"
                                color="indigo.500"
                                mb={10}
                                maxW={isTablet ? '80%' : '100%'}
                            >
                                Logiciel de planning pour infirmier (IDE) en EHPAD
                            </Heading>

                            <Text color="indigo.500" mb="12" maxW={isTablet ? '65%' : '650'}>
                                Libérez votre potentiel et concentrez-vous sur votre travail : Staffea simplifie votre
                                quotidien. Grâce à son application, passez moins de temps à gérer votre planning en
                                EHPAD et gagnez du temps sur vos tâches.
                                <br />
                                <br /> Votre planning est mis à jour en temps réel et vous pouvez solliciter des
                                modifications à tout moment, directement sur l&#39;application. Soyez plus serein !
                            </Text>

                            <Center alignSelf={!isTablet ? 'center' : 'inherit'}>
                                <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                    <CtaButton
                                        text="Demander une démo"
                                        description="Démonstration de 45 minutes en visioconférence"
                                    />
                                </a>
                            </Center>
                        </Flex>
                    </Section>

                    <Section
                        bg="white"
                        id="nurse-planning-features-section"
                        name="fonctionnalités"
                        footer={
                            <Section bg="indigo.100">
                                <Box py={20}>
                                    <Center textAlign="center">
                                        <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                            Une solution pour toutes les professions en EHPAD
                                        </Text>
                                    </Center>
                                    <Text
                                        fontSize="md"
                                        color="indigo.500"
                                        style={{ columnCount: isMobile ? 1 : 2, columnGap: '30px' }}
                                    >
                                        La plateforme Staffea répond parfaitement aux besoins de votre planning
                                        d&#39;IDE en EHPAD. Vous pouvez aisément demander des modifications de roulement
                                        ou des remplacements à vos collègues, et ce depuis l&#39;application mobile.
                                        Gagnez en efficacité et en sérénité. Cette solution est également conçue pour
                                        s&#39;adapter aux&nbsp;
                                        <Link
                                            to="/ehpad/lp/planning-aide-soignant"
                                            variant="underlineBold"
                                            _hover={{ color: 'green.500' }}
                                        >
                                            plannings des aides-soignants en EHPAD
                                        </Link>
                                        &nbsp;et aux&nbsp;
                                        <Link
                                            to="/ehpad/lp/planning-ash"
                                            variant="underlineBold"
                                            _hover={{ color: 'green.500' }}
                                        >
                                            plannings des ASH en EHPAD
                                        </Link>
                                        &nbsp; .Toutes les professions médicales et soignantes sont intégrées à la
                                        solution Staffea, pour aider le personnel à gagner du temps et à mieux vivre au
                                        quotidien.
                                    </Text>
                                </Box>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                Des fonctionnalités astucieuses
                                <Text as="span" color="green.500" display="block">
                                    pour simplifier la gestion de votre planning.
                                </Text>
                            </Text>

                            <Flex my="10" gap="20" direction="column" wrap="wrap" mx="auto">
                                {functionalities.map(({ id, subTitle, title, image, list }) => (
                                    <>
                                        <HorizontalCard
                                            w={['xs', 'sm', '2xl', '3xl', '4xl']}
                                            mediaW={['xs', 'sm', 'md']}
                                            contentW={['xs', 'sm', 'md']}
                                            mediaFlexBasis={['50%', '50%', '50%', '70%']}
                                            isReverse={id % 2 === 0}
                                            gap={['4', '8']}
                                            hasCenteredContent={true}
                                            media={
                                                <Image
                                                    src={image}
                                                    objectFit="cover"
                                                    maxW="60"
                                                    bgColor={'indigo.100'}
                                                    px={30}
                                                />
                                            }
                                            title={
                                                <Box>
                                                    <Text fontSize="sm" fontWeight="bold" color="green.500">
                                                        {subTitle}
                                                    </Text>

                                                    <Text as="h3" fontWeight="bold" color="indigo.500" mb="4">
                                                        {title}
                                                    </Text>
                                                </Box>
                                            }
                                            content={<IconItemList list={list} />}
                                        />
                                    </>
                                ))}
                            </Flex>

                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" pt={10}>
                                Les points forts de Staffea
                                <Text as="span" color="green.500" display="block">
                                    pour optimiser votre quotidien d&rsquo;infirmier
                                </Text>
                            </Text>

                            <HorizontalCarousel
                                content={horizontalCarouselContent.map(({ header, title, text, image, alt }) => {
                                    return {
                                        title: header,
                                        description: (
                                            <>
                                                <Text color="indigo.500" fontSize="xs" align="start" mb="4">
                                                    {title}
                                                </Text>

                                                <Text color="indigo.500" fontSize="xs" align="start">
                                                    {text}
                                                </Text>
                                            </>
                                        ),
                                        image: <Image key={uuidv4()} src={image} alt={alt} />,
                                    }
                                })}
                            />
                        </Box>
                    </Section>

                    <Section bg="white" id="nurse-planning-mission-section" name="mission">
                        <Box py={20}>
                            <Center>
                                <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                    Au cœur de la démarche de Staffea :&nbsp;
                                    <Text as="span" color="green.500">
                                        la qualité de vie au travail.
                                    </Text>
                                </Text>
                            </Center>

                            <Flex gap="14" direction="row" wrap="wrap" mt="8" justify="center">
                                {mission.map(({ id, title, content, svg }) => (
                                    <Flex key={id} direction="column" maxW="290px">
                                        <Image src={svg} maxW="250px" objectFit="cover" mb="2" />

                                        <Text as="h3" color="green.500" fontSize="sm" fontWeight="bold" mb="4">
                                            {title}
                                        </Text>

                                        {content.map((parahraph) => (
                                            <Text key={uuidv4()} color="indigo.500" fontSize="xs" mb="4">
                                                {parahraph}
                                            </Text>
                                        ))}
                                    </Flex>
                                ))}
                            </Flex>
                        </Box>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default NursePlanningPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["ehpad", "404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
